import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { GatsbyImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Top from '../components/top'
import Footer from '../components/footer'
import Header from "../components/header"
import Contact from '../components/contact'
import Text from '../components/text'
import TextImage from '../components/text_image'
import Features from '../components/features'
import Entries from '../components/entries'
import Faq from '../components/faq'

const Services = ({data}) => {
  const entry = data.prismicServices
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Top />
      <Header />

      <div className="w-full py-16 relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="w-full h-full object-cover block" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
        </div>
        <div className="absolute -inset-0">
          <div className="w-full h-full bg-black opacity-50"></div>
        </div>
        <div className="relative">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="flex-none sm:flex justify-between gap-16">
              <div className="w-full sm:w-8/12">
                <div className="text-4xl sm:text-5xl text-white">
                  <h1><span className="text-white mr-3">|</span>{entry.data.heading}</h1>
                </div>
              </div>
              <div className="hidden sm:w-4/12">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-4">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex items-center justify-end gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
            <Link to="/">Home</Link>
            /
            <Link to="/services/">Services</Link>
          </div>
        </div>
      </div>

      {entry.data.body.map((slice) => {
        if (slice.slice_type === 'text') {
          return (
            <div key={slice.id}>
              <Text primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'text_image') {
          return (
            <div key={slice.id}>
              <TextImage primary={slice.primary} />
            </div>
          )
        }
        if (slice.slice_type === 'features') {
          return (
            <div key={slice.id}>
              <Features primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'entries') {
          return (
            <div key={slice.id}>
              <Entries primary={slice.primary} items={slice.items} />
            </div>
          )
        }
        if (slice.slice_type === 'faq') {
          return (
            <div key={slice.id}>
              <Faq primary={slice.primary} items={slice.items} />
            </div>
          )
        }
      })}

      <Contact />
      <Footer />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicServices(uid: {eq: $handle}) {
    data {
      meta_description
      page_title
      heading
      image {
        gatsbyImageData
        alt
      }
      body {
        ... on PrismicServicesDataBodyText {
          id
          primary {
            text {
              richText
            }
          }
          slice_type
        }
        ... on PrismicServicesDataBodyTextImage {
          id
          slice_type
          primary {
            layout
            text {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
            button
            page_link {
              url
              uid
              type
              link_type
            }
          }
        }
        ... on PrismicServicesDataBodyFeatures {
          id
          slice_type
          primary {
            heading1
            text {
              richText
            }
          }
          items {
            text {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
          }
        }
        ... on PrismicServicesDataBodyEntries {
          id
          slice_type
          primary {
            heading1
            text {
              richText
            }
          }
          items {
            heading1
            text {
              richText
            }
            image1 {
              alt
              gatsbyImageData
            }
            page_link {
              url
              uid
              type
            }
          }
        }
        ... on PrismicServicesDataBodyFaq {
          id
          slice_type
          primary {
            heading1
            text {
              richText
            }
          }
          items {
            heading1
            text {
              richText
            }
          }
        }
      }
    }
  }
}
`

export default Services
